import React from 'react';
import PropTypes from 'prop-types';

import classes from './Field.scss';

function Field({ children }) {
  return <div className={classes.root}>{children}</div>;
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Field;
