import React from 'react';
import PropTypes from 'prop-types';

import classes from './TextField.scss';

function TextField({ name, required = false }) {
  return (
    <input
      className={classes.root}
      type="text"
      name={name}
      required={required}
    />
  );
}

TextField.defaultProps = {
  required: false,
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default TextField;
