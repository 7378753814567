import React from 'react';
import PropTypes from 'prop-types';

import classes from './TextArea.scss';

function TextArea({ name, required = false }) {
  return <textarea className={classes.root} name={name} rows={8} required={required} />;
}

TextArea.defaultProps = {
  required: false,
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default TextArea;
