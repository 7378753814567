import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import Heading from 'components/Heading';
import Text from 'components/Text';
import Field from 'components/Field';
import Label from 'components/Label';
import TextField from 'components/TextField';
import TextArea from 'components/TextArea';
import Button from 'components/Button';

import classes from './ContactForm.scss';

import config from '../../config';

function ContactForm({ title, children }) {
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    setError(false);

    const items = Object.fromEntries(
      Object.values(event.target.elements)
        .map((element) => [element.name, element.value])
        .filter((item) => item[0]),
    );

    fetch(`${config.apiUrl}api/forms/submit/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cockpit-Token': config.apiToken,
      },
      body: JSON.stringify({
        form: items,
      }),
    })
      .then(() => {
        setSend(true);
        navigate('/bedankt');
      })
      .catch(() => setError(true));
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Heading as="h2" size="xl">
          {title}
        </Heading>

        <div
          className={classNames({
            [classes.message]: true,
            [classes.show]: send,
          })}
        >
          <Text color="white">Het formulier is verzonden.</Text>
        </div>

        <div
          className={classNames({
            [classes.message]: true,
            [classes.error]: true,
            [classes.show]: error,
          })}
        >
          <Text color="white">Alle velden zijn verplicht.</Text>
        </div>

        <form
          className={classNames({
            [classes.form]: true,
            [classes.hide]: send,
          })}
          onSubmit={handleSubmit}
          method="post"
        >
          <Field>
            <Label name="company">Bedrijf</Label>
            <TextField name="company" />
          </Field>

          <Field>
            <Label name="name" required>
              Voor- en achternaam
            </Label>
            <TextField name="name" required />
          </Field>

          <Field>
            <Label name="email" required>
              E-mailadres
            </Label>
            <TextField name="email" required />
          </Field>

          <Field>
            <Label name="phone" required>Telefoonnummer</Label>
            <TextField name="phone" required />
          </Field>

          <Field>
            <Label name="message" required>
              Bericht
            </Label>
            <TextArea name="message" required />
          </Field>

          <Button as="button" type="submit" color="tertiary">
            Verstuur bericht
          </Button>
        </form>
      </div>

      <div className={classes.content}>{children}</div>
    </div>
  );
}

ContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContactForm;
