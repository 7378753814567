import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Label.scss';

function Label({ name, children, required }) {
  const className = classNames({
    [classes.root]: true,
    [classes.required]: required,
  });
  return (
    <label className={className} htmlFor={name}>
      {children}
    </label>
  );
}

Label.defaultProps = {
  required: false,
};

Label.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
};

export default Label;
